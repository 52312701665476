import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const InstaReducer = (state, action) => {
    switch (action.type) {
        case "get_all_insta":
            return { ...state, InstaList: action.payload };
        case "get_all_insta_status":
            return { ...state, InstaListStatus: action.payload };
        case "clear_get_all_insta_status":
            return { ...state, InstaListStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllInsta = (dispatch) => async () => {
    try {
        const response = await listAPI.get('/insta/getAllInsta');
        dispatch({ type: "get_all_insta", payload: response.data });
        dispatch({ type: "get_all_insta_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_insta_status", payload: 400 });
    }
};

const clearGetAllInstaStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_insta_status" });
};





export const { Provider, Context } = CreateDataContext(
    InstaReducer,
    {
        getAllInsta, clearGetAllInstaStatus,
    },
    {
        InstaList: [],
        InstaListStatus: "",
    }
);
