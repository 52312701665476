import React, { useState, useEffect, useContext } from "react";
import { Grid, Typography, Box, TextField, Button } from "@mui/material";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Colors from "../../asstes/color";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Context as EventContext } from "../../context/EventContext";
import { format } from 'date-fns';

const Calendar = () => {
    const [events, setEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [days, setDays] = useState([]);
    const [formValues, setFormValues] = useState({
        Name: '',
        Phone: '',
        Email: '',
    });
    const [selectedEvent, setSelectedEvent] = useState([]);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const { state: { EventList, EventListStatus, CreateBookingStatus }, getAllEvents, clearGetAllEventsStatus, createBooking, clearCreateBookingStatus, } = useContext(EventContext);

    useEffect(() => {
        getAllEvents()
    }, []);

    useEffect(() => {
        const filteredEvents = EventList?.data?.filter((event) => {
            const eventDate = new Date(event.event_date);
            return (
                eventDate.getFullYear() === currentDate.getFullYear() &&
                eventDate.getMonth() === currentDate.getMonth()
            );
        }).map((event) => ({
            id: event?._id,
            date: new Date(event.event_date).getDate(),
            name: event.event_name,
            description: event.event_description,
            image: event.image,
            date_time: event.event_date,
            time: event.event_time
        }));
        setEvents(filteredEvents);
    }, [EventList, EventListStatus, currentDate]);


    useEffect(() => {
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const daysInMonth = Array.from({ length: lastDayOfMonth.getDate() }, (_, i) => i + 1);
        setDays(daysInMonth);
    }, [currentDate]);

    useEffect(() => {
        if (CreateBookingStatus === 201) {
            console.log("success")
            setOpen2(true)
            setFormValues({
                Name: '',
                Phone: '',
                Email: '',
            })
            setSelectedEvent([])
            clearCreateBookingStatus();
        } else if (CreateBookingStatus === 400 || CreateBookingStatus === 404) {
            clearCreateBookingStatus();
        }
    }, [CreateBookingStatus]);


    const handleSubmit = () => {
        try {
            if (selectedEvent.length === 0) {
                setOpen1(true);
            } else if (formValues.Name === '') {
                setOpen(true);
            } else if (formValues.Email === '') {
                setOpen(true);
            } else if (formValues.Phone === '') {
                setOpen(true);
            } else {
                createBooking({
                    name: formValues.Name,
                    email: formValues.Email,
                    phone: formValues.Phone,
                    event_id: selectedEvent?.id,
                });
            }
        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };

    const handleInputChange = (label, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [label]: value,
        }));
    };

    const handleGridClick = (day, event) => {
        if (event) {
            setSelectedEvent(event)
            // setFormattedDate(formatDate(event.event_date));
        } else {
            setSelectedEvent([])
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClose1 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen1(false);
    };

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen2(false);
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), "MMM do"); // Output: "Nov 21st"
    };

    const renderDays = () => {
        const daysInWeek = 7;
        const totalDays = days.length;
        const additionalBoxes = (daysInWeek - (totalDays % daysInWeek)) % daysInWeek;
        return (
            <>
                {days?.map((day) => {
                    const event = events?.find((e) => e.date === day);
                    return (
                        <Grid
                            item
                            xs={1.71} sm={2.57} md={1.92} lg={1.67} xl={1} //lg1.3 md 1.5 sm 2 xs 4
                            key={day}
                            onClick={() => handleGridClick(day, event)}
                            sx={{ height: { xs: "70px", md: "140px" } }}
                            style={{ cursor: 'pointer', border: "1px solid #2BB1E6", borderRadius: '10px', backgroundColor: event ? "rgba(43, 177, 230, 0.2)" : "transparent" }}
                        >
                            <Typography variant="body1" sx={{   fontSize: { xs: 10, sm: 15 },fontFamily: 'MyCustomFont1', }}>
                                {day}
                            </Typography>
                            {event && (
                                <Typography variant="body2" align="center" color="primary" sx={{
                                    fontFamily: 'MyCustomFont1',
                                    fontWeight: 400,
                                    fontSize: { xs: 10, sm: 15 },
                                    color: Colors.black,
                                    mt: { xs: 0, md: 2 }
                                }}>
                                    {event.name}
                                </Typography>
                            )}
                        </Grid>
                    );
                })}

                {[...Array(additionalBoxes)].map((_, index) => (
                    <Grid
                        item
                        xs={1.71} sm={2.57} md={1.92} lg={1.67} xl={1}
                        key={`empty-${index}`}
                        sx={{
                            height: { xs: "70px", md: "140px" },
                            border: "1px solid #2BB1E6",
                            borderRadius: '10px',
                        }}
                    />
                ))}
            </>
        )
    };

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            <Typography variant="h4" align="center" color="primary" gutterBottom sx={{
                fontFamily: 'MyCustomFont',
                fontWeight: 700,
                fontSize: { xs: 24, sm: 30, md: 35 },
                color: Colors.textSecondary,
                textDecoration: 'none',
                mt: 2
            }}>
                Events
            </Typography>
            <Grid container spacing={1} sx={{ maxWidth: "1200px", margin: "0 auto", mb: 3 }}>
                <Grid
                    item
                    xs={12}
                    sm={18}
                    md={13.5}
                    lg={11.7}
                    xl={12}
                    sx={{
                        // height: "80px",
                        // display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: Colors.green,
                        borderRadius: "10px",
                        border: "1px solid #2BB1E6",
                        padding: { xs: 1, sm: 1 },
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: "MyCustomFont1",
                            fontWeight: 600,
                            fontSize: { xs: 16, sm: 20, md: 22 },
                            color: Colors.white,
                        }}
                    >
                        {currentDate.toLocaleString("default", { month: "long" })}
                    </Typography>
                </Grid>
                {renderDays()}
            </Grid>
            <Box sx={{ backgroundColor: Colors.textSecondary, padding: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            paddingLeft: { xs: 2, sm: 8 },
                            maxWidth: '600px',
                            // mx: "auto",
                            color: Colors.white
                        }}>
                            <Typography variant="h5" sx={{ color: Colors.white, mb: 2 }}>Sign Up</Typography>

                            {selectedEvent.length !== 0 &&
                                <Typography variant="subtitle1" sx={{ color: Colors.white, mb: 2 }}>{selectedEvent?.name}  {formatDate(selectedEvent.date_time)}  {selectedEvent?.time}</Typography>}

                            {["Name", "Phone", "Email"].map((label, index) => (
                                <Box key={index} sx={{ mb: 1, mt: 1 }}>
                                    <Typography variant="subtitle1" sx={{ color: Colors.white, mb: 0 }}>
                                        {label}*
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        value={formValues[label]}
                                        onChange={(e) => handleInputChange(label, e.target.value)}
                                        InputProps={{
                                            style: {
                                                borderRadius: '30px',
                                                backgroundColor: Colors.textSecondary,
                                            }
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                height: 35,
                                                "& input": {
                                                    padding: "24px 20px",
                                                    color: Colors.white,
                                                    // textAlign: "center",
                                                },
                                                "& fieldset": {
                                                    borderColor: Colors.white,
                                                },
                                                "&:hover fieldset, &.Mui-focused fieldset": {
                                                    borderColor: Colors.white,
                                                }
                                            },
                                            "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                                color: Colors.white,
                                            },
                                            "& .MuiInputLabel-root.Mui-error": {
                                                color: "red",
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Grid>

                    {selectedEvent.length !== 0 &&
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    width: '80%',
                                    height: { xs: '150px', sm: '200px', md: '250px' },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <img
                                    src={selectedEvent?.image}
                                    alt="Signup illustration"
                                    style={{
                                        width: '300px',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: '10px',
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    // height: { xs: '150px', sm: '200px', md: '250px' },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ color: Colors.white, mt: 1, alignItems: 'center', justifyContent: 'center' }}>{selectedEvent?.description}</Typography>
                            </Box>
                        </Grid>}

                </Grid>
                <Box sx={{ marginTop: 1, paddingLeft: { xs: 2, sm: 8 }, }}>
                    <Button variant="contained" sx={{ mt: 2, bgcolor: 'white', color: 'black' }} onClick={() => handleSubmit()}>
                        Submit
                    </Button>
                </Box>
            </Box>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Please fill required field!
                </Alert>
            </Snackbar>

            <Snackbar open={open1} autoHideDuration={3000} onClose={handleClose1}>
                <Alert
                    onClose={handleClose1}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Please select an event!
                </Alert>
            </Snackbar>

            <Snackbar open={open2} autoHideDuration={3000} onClose={handleClose2}>
                <Alert
                    onClose={handleClose2}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Event signup success!
                </Alert>
            </Snackbar>

            {/* footer */}
            <Footer />
        </Box>
    );
};

export default Calendar;
