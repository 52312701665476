import React, { useState, useContext, useEffect } from 'react';
import { Container, Box, Typography, Button, Divider } from '@mui/material';
import { styled } from '@mui/system';
import Colors from '../../asstes/color';
import { Context as EventContext } from "../../context/EventContext";

const events = [
    {
        title: "Newyork Dog Run",
        date: "10/18/2024",
        description: "This is dog run where the dogs will run, yea they’ll run we got runnin dogs because they run, right? Sure, anyway, here’s Freebird ",
    },
    {
        title: "Doggy Paddle gfdg",
        date: "10/19/2024",
        description: "After they run, they can paddle. (weather permitting) Despite their inability to grip paddles they will provide moral while on a paddleboard",
    },
    {
        title: "Cafe Meet/Greet",
        date: "10/21/2024",
        description: "Dogs need to socialize, so do humans. (supposedly) Come to the cafe to hang out with us and dogs!",
    },
    {
        title: "Dog Run 2",
        date: "10/23/2024",
        description: "Science says dogs need to run more than once. When we found this out we added another day to do exactly that.",
    },
];

// Styled component for horizontal scrolling without borders around cards
const HorizontalScroll = styled(Box)({
    display: 'flex',
    overflowX: 'auto',
    gap: '1rem',
    padding: '1rem 0',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
});

const EventCard = styled(Box)({
    minWidth: 250,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15
    // alignItems: 'center',
    // textAlign: 'center',
});

const TextTopic = styled(Typography)({
    // mr: 2, ml: 4,
    display: { xs: 'flex', md: 'flex' },
    fontFamily: 'MyCustomFont1',
    fontWeight: 600,
    fontSize: '20px',
    // letterSpacing: '.3rem',
    color: Colors.textSecondary,
    textDecoration: 'none', mt: 0
});

function EventView() {
    const [latestEvents, setLatestEvents] = useState([]);
    const { state: { EventList, EventListStatus }, getAllEvents, clearGetAllEventsStatus, } = useContext(EventContext);

    useEffect(() => {
        getAllEvents()
    }, []);


    useEffect(() => {
        // Sort the data by createdAt in descending order
        const sortedData = EventList?.data?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const latestSevenEvents = sortedData?.slice(0, 7);
        setLatestEvents(latestSevenEvents);
    }, [EventList]);

    return (
        <Box>
            <Typography variant="h4" gutterBottom sx={{
                flexGrow: 1,
                mr: 2, ml: 4,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'MyCustomFont1',
                fontWeight: 700,
                fontSize: '40px',
                // letterSpacing: '.3rem',
                color: Colors.textSecondary,
                textDecoration: 'none', mt: 6
            }}>
                Events
            </Typography>
            <HorizontalScroll>
                {latestEvents?.map((event, index) => (
                    <React.Fragment key={index}>
                        <EventCard>
                            <TextTopic variant="h6" >
                                {event.event_name}
                            </TextTopic>
                            <Typography variant="body2" sx={{
                                flexGrow: 1,
                                // mr: 2, ml: 4,
                                display: { xs: 'flex', md: 'flex' },
                                fontFamily: 'MyCustomFont1',
                                fontWeight: 400,
                                fontSize: '15px',
                                // letterSpacing: '.3rem',
                                color: Colors.textSecondary,
                                textDecoration: 'none', mt: 1
                            }}>
                                {event.event_date}
                            </Typography>
                            <Typography variant="body2" gutterBottom sx={{
                                flexGrow: 1,
                                // mr: 2, ml: 4,
                                display: { xs: 'flex', md: 'flex' },
                                fontFamily: 'MyCustomFont1',
                                fontWeight: 400,
                                fontSize: '15px',
                                // letterSpacing: '.3rem',
                                color: Colors.textSecondary,
                                textDecoration: 'none', mt: 1
                            }}>
                                {event.event_description}
                            </Typography>
                            <Button variant="text" color="primary">
                                Join
                            </Button>
                        </EventCard>
                        {index < latestEvents?.length + 1 && (
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ height: 'auto', alignSelf: 'stretch', bgcolor: Colors.seperatorColor }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </HorizontalScroll>
        </Box>
    );
}

export default EventView;
