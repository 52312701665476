import logo from './logo.svg';
import './App.css';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Provider as BlogProvider } from "./context/BlogContext";
import { Provider as EventProvider } from "./context/EventContext";
import { Provider as InstaProvider } from "./context/InstaContext";
import Calendar from './screens/Events/Calander';
import PrivacyPolicyPaws from './screens/PrivacyPolicy/PrivacyPolicyPaws';
import TermsOfUsePaws from './screens/PrivacyPolicy/TermsOfUsePaws';
import TermsOfUseSocial from './screens/PrivacyPolicy/TermsOfUseSocial';
import PawPledge from './screens/PrivacyPolicy/PawPledge';
import SocialPledge from './screens/PrivacyPolicy/SocialPledge';
import HowItsWork from './screens/HowItsWork/HowItsWork';
import MeetTeam from './screens/TeamMembers/MeetTeam';
import OriginStory from './screens/Originstory/OriginStory';
import BlogScreen from './screens/Blog/BlogScreen';
import BlogView from './screens/Blog/BlogView';
import FaqScreen from './screens/FAQ/FaqScreen';
import GalleryScreen from './screens/Gallery/GalleryScreen';
import GalleryDetailScreen from './screens/Gallery/GalleryDetailScreen';
const theme = createTheme();

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BlogProvider>
          <EventProvider>
            <InstaProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="/events" element={<Calendar />} />
                  <Route path="/how_its_work" element={<HowItsWork />} />
                  <Route path="/team" element={<MeetTeam />} />
                  <Route path="/origin_story" element={<OriginStory />} />
                  <Route path="/blog" element={<BlogScreen />} />
                  <Route path="/blog/:postId" element={<BlogView />} />
                  <Route path="/faqs" element={<FaqScreen />} />
                  <Route path="/gallery" element={<GalleryScreen />} />
                  <Route path="/gallery/details" element={<GalleryDetailScreen />} />

                  <Route path="/cms/PAWS" element={<PrivacyPolicyPaws />} />
                  <Route path="/cms/TC-SOCIAL" element={<TermsOfUseSocial />} />
                  <Route path="/cms/TC-PAWS" element={<TermsOfUsePaws />} />
                  <Route path="/cms/PLEDGE-PAWS" element={<PawPledge />} />
                  <Route path="/cms/PLEDGE-SOCIAL" element={<SocialPledge />} />
                </Routes>
              </Router>
            </InstaProvider>
          </EventProvider>
        </BlogProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
