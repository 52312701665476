// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
// import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
// import Colors from '../asstes/color'
// import logo from "../asstes/images/logo.png";


// const pages = ['Events', 'FAQs', 'Blog', 'About Us'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// function NavBar() {
//     const [anchorElNav, setAnchorElNav] = React.useState(null);
//     const [anchorElUser, setAnchorElUser] = React.useState(null);

//     const handleOpenNavMenu = (event) => {
//         setAnchorElNav(event.currentTarget);
//     };
//     const handleOpenUserMenu = (event) => {
//         setAnchorElUser(event.currentTarget);
//     };

//     const handleCloseNavMenu = () => {
//         setAnchorElNav(null);
//     };

//     const handleCloseUserMenu = () => {
//         setAnchorElUser(null);
//     };

//     return (
//         <AppBar position="static" style={{ backgroundColor: Colors.background }}>
//             <Container maxWidth="xl">
//                 <Toolbar disableGutters>
//                     <img style={{ height: '52px', width: '52px', top: '36px', left: '46px' }} src={logo} alt="logo" />
//                     <Typography
//                         // variant="h6"
//                         noWrap
//                         component="a"
//                         // href="#app-bar-with-responsive-menu"
//                         sx={{
//                             mr: 2, ml: 2,
//                             display: { xs: 'none', md: 'flex' },
//                             fontFamily: 'MyCustomFont',
//                             fontWeight: 700,
//                             fontSize: 32,
//                             // letterSpacing: '.3rem',
//                             color: Colors.textPrimary,
//                             textDecoration: 'none',
//                         }}
//                     >
//                         Social Paws
//                     </Typography>

//                     {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
//                         <IconButton
//                             size="large"
//                             aria-label="account of current user"
//                             aria-controls="menu-appbar"
//                             aria-haspopup="true"
//                             onClick={handleOpenNavMenu}
//                             color="inherit"
//                         >
//                             <MenuIcon />
//                         </IconButton>
//                         <Menu
//                             id="menu-appbar"
//                             anchorEl={anchorElNav}
//                             anchorOrigin={{
//                                 vertical: 'bottom',
//                                 horizontal: 'left',
//                             }}
//                             keepMounted
//                             transformOrigin={{
//                                 vertical: 'top',
//                                 horizontal: 'left',
//                             }}
//                             open={Boolean(anchorElNav)}
//                             onClose={handleCloseNavMenu}
//                             sx={{ display: { xs: 'block', md: 'none' } }}
//                         >
//                             {pages.map((page) => (
//                                 <MenuItem key={page} onClick={handleCloseNavMenu}>
//                                     <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
//                                 </MenuItem>
//                             ))}
//                         </Menu>
//                     </Box>
//                     <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
//                     <Typography
//                         variant="h5"
//                         noWrap
//                         component="a"
//                         href="#app-bar-with-responsive-menu"
//                         sx={{
//                             mr: 2,
//                             display: { xs: 'flex', md: 'none' },
//                             flexGrow: 1,
//                             fontFamily: 'monospace',
//                             fontWeight: 700,
//                             letterSpacing: '.3rem',
//                             color: Colors.textPrimary,
//                             textDecoration: 'none',
//                         }}
//                     >
//                         LOGO
//                     </Typography> */}

//                     <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: '726px' }}>
//                         {pages.map((page, index) => (
//                             <Button
//                                 key={page}
//                                 onClick={handleCloseNavMenu}
//                                 sx={{
//                                     my: 2, color: Colors.textPrimary, display: 'block', borderRadius: '22px', border: `1.5px solid ${Colors.textPrimary}`, ml: index === 0 ? -2 : 0,
//                                     mr: 2, fontFamily: 'MyCustomFont', fontSize: '14px', fontWeight: 700, textTransform: 'none', py: 0.3,
//                                 }}
//                             >
//                                 {page}
//                             </Button>
//                         ))}
//                     </Box>
//                     {/* </Box> */}
//                 </Toolbar>
//             </Container>
//         </AppBar>
//     );
// }
// export default NavBar;


import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Colors from '../asstes/color';
import logo from "../asstes/images/logo.png";
import { Link } from 'react-router-dom';

const drawerWidth = 240;
const navItems = [
    { label: 'Events', path: '/events' },
    { label: 'FAQs', path: '/faqs' },
    { label: 'Blog', path: '/blog' },
    { label: 'About Us', path: '/team' },
];
function NavBar(props) {
    const { window, screen } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" component={Link}
                to="/" sx={{
                    my: 2, fontFamily: 'MyCustomFont1', textDecoration: 'none', mt: 4, color: Colors.textPrimary, fontWeight: 700,
                    fontSize: 32,
                }}>
                Social Paws
            </Typography>
            {/* <Divider /> */}
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} component={Link}
                            to={item.path} >
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'block', width: '100%' }}>
            {/* <CssBaseline /> */}
            <AppBar component="nav" position="static" style={{ backgroundColor: screen === "event" ? Colors.white : Colors.background, boxShadow: 'none', width: '100%' }}>
                {/* position="static" */}
                <Toolbar>
                    <img style={{ height: '52px', width: '52px', top: '36px', left: '46px' }} src={logo} alt="logo" />
                    <IconButton
                        color={Colors.textPrimary}
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{
                            ml: 'auto',
                            display: { sm: 'none' }
                        }}
                    >
                        <MenuIcon sx={{ fontSize: 52 }} />
                    </IconButton>

                    <Typography
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            flexGrow: 1,
                            mr: 2, ml: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'MyCustomFont',
                            fontWeight: 700,
                            fontSize: 32,
                            // letterSpacing: '.3rem',
                            color: Colors.textPrimary,
                            textDecoration: 'none',
                        }}
                    >
                        Social Paws
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item.label} component={Link}
                                to={item.path} sx={{ color: Colors.textSecondary, borderRadius: '22px', border: `1.7px solid ${Colors.textSecondary}`, mr: 2, fontFamily: 'MyCustomFont', fontSize: '14px', fontWeight: 700, textTransform: 'none', py: 0.3, }}>
                                {item.label}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    anchor="right"
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: Colors.background },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

        </Box>
    );
}

NavBar.propTypes = {
    window: PropTypes.func,
};

export default NavBar;