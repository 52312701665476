import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, Divider, List, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Box from '@mui/material/Box';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Colors from '../../asstes/color';
import { faqData } from '../../components/data';

function FaqScreen() {

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h3" color="primary" gutterBottom sx={{
                        fontFamily: 'MyCustomFont',
                        // left: '50%',
                        fontWeight: 700,
                        color: Colors.textSecondary,
                        fontSize: { xs: '1.8rem', md: '3rem' },
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        // zIndex: 1,
                    }}>
                        FAQ
                    </Typography>
                </Grid>
            </Grid>

            {/* FAQ Sections */}
            <Box sx={{ padding: 3 }}>
                {faqData.map((section, index) => (
                    <Box key={index} sx={{ mb: 3 }}>
                        <Accordion sx={{ boxShadow: 'none' }}>
                            <AccordionSummary
                                expandIcon={<FavoriteIcon style={{ color: Colors.textSecondary }} />}
                                // expandIcon={null}
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Typography variant="h5" sx={{
                                    display: { xs: 'flex', md: 'flex' },
                                    fontFamily: 'MyCustomFont',
                                    fontWeight: 700,
                                    fontSize: { xs: '23px', md: '30px' },
                                    color: Colors.black,
                                    textDecoration: 'none', mt: 0
                                }}>
                                    {section.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {section.subtopics.length > 0 ? (
                                    section.subtopics.map((subtopic, idx) => (
                                        <Accordion key={idx} sx={{
                                            mb: 0, boxShadow: 'none', '&:before': {
                                                display: 'none',
                                            },
                                        }}>
                                            <AccordionSummary
                                                expandIcon={null}
                                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 0 }}
                                            >
                                                <Typography sx={{
                                                    flex: { xs: 1, md: 0.5 },
                                                    display: { xs: 'flex', md: 'flex' },
                                                    fontFamily: 'MyCustomFont',
                                                    fontWeight: 700,
                                                    fontSize: { xs: '18px', md: '23px' },
                                                    color: Colors.textSecondary,
                                                    textDecoration: 'none', mt: 0, ordBreak: 'break-word',
                                                }}>{subtopic.question}</Typography>
                                                <IconButton color="primary">
                                                    <FavoriteIcon style={{ color: Colors.textSecondary }} sx={{ alignSelf: 'flex-start', }} />
                                                </IconButton>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Box sx={{ mb: 2 }}>

                                                    {Array.isArray(subtopic.answer)
                                                        ? subtopic.answer.map((answer, answerIndex) => {
                                                            if (typeof answer === 'string') {

                                                                return (
                                                                    <Typography
                                                                        key={answerIndex}
                                                                        sx={{
                                                                            fontFamily: 'MyCustomFont1',
                                                                            fontWeight: 600,
                                                                            fontSize: { xs: '16px', md: '20px' },
                                                                            color: Colors.black,
                                                                            mt: 1,
                                                                            mb: 2,
                                                                        }}
                                                                    >
                                                                        {answer}
                                                                    </Typography>
                                                                );
                                                            } else if (typeof answer === 'object') {

                                                                return (
                                                                    <Box key={answerIndex} sx={{ mb: 3 }}>
                                                                        {/* Render the topic */}
                                                                        {answer.topic && answer.topic.trim() && (
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily: 'MyCustomFont',
                                                                                    fontWeight: 700,
                                                                                    fontSize: { xs: '18px', md: '22px' },
                                                                                    color: Colors.textSecondary,
                                                                                    mb: 1,
                                                                                }}
                                                                            >
                                                                                {answer.topic}
                                                                            </Typography>
                                                                        )}

                                                                        {/* Render the description */}
                                                                        {answer.description && (
                                                                            <Typography
                                                                                sx={{
                                                                                    fontFamily: 'MyCustomFont1',
                                                                                    fontWeight: 600,
                                                                                    fontSize: { xs: '16px', md: '20px' },
                                                                                    color: Colors.black,
                                                                                    mb: 2,
                                                                                }}
                                                                            >
                                                                                {answer.description}
                                                                            </Typography>
                                                                        )}

                                                                        {/* Render the list */}
                                                                        {answer.list && (
                                                                            <List sx={{ pl: 3 }}>
                                                                                {answer.list.map((item, listIndex) => (
                                                                                    <ListItem
                                                                                        key={listIndex}
                                                                                        sx={{
                                                                                            fontFamily: 'MyCustomFont1',
                                                                                            fontWeight: 600,
                                                                                            fontSize: { xs: '16px', md: '20px' },
                                                                                            color: Colors.black,
                                                                                            mb: 1,
                                                                                        }}
                                                                                    >
                                                                                        • {item}
                                                                                    </ListItem>
                                                                                ))}
                                                                            </List>
                                                                        )}
                                                                    </Box>
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                        : (
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'MyCustomFont1',
                                                                    fontWeight: 600,
                                                                    fontSize: { xs: '16px', md: '20px' },
                                                                    color: Colors.black,
                                                                    mt: 1,
                                                                    mb: 2,
                                                                }}
                                                            >
                                                                {subtopic.answer}
                                                            </Typography>
                                                        )}
                                                </Box>
                                                {/* ))} */}
                                            </AccordionDetails>
                                            {/* {idx !== section.subtopics.length - 1 && (
                                                // <Divider sx={{ borderColor: 'red' }} /> // Red divider between subtopics
                                            )} */}
                                        </Accordion>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        No data available.
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                        {index !== faqData.length + 1 && (
                            <Divider sx={{ borderColor: Colors.textSecondary, marginY: 2 }} />
                        )}
                    </Box>
                ))
                }
            </Box >


            <Footer />
        </Box >
    );
}

export default FaqScreen;
