import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const EventReducer = (state, action) => {
    switch (action.type) {
        case "get_all_events":
            return { ...state, EventList: action.payload };
        case "get_all_events_status":
            return { ...state, EventListStatus: action.payload };
        case "clear_get_all_events_status":
            return { ...state, EventListStatus: "" };

        case "create_booking":
            return { ...state, CreateBooking: action.payload };
        case "create_booking_status":
            return { ...state, CreateBookingStatus: action.payload };
        case "clear_create_booking_status":
            return { ...state, CreateBookingStatus: "" };

        case "delete_event_status":
            return { ...state, DeleteEventStatus: action.payload };
        case "clear_delete_event_status":
            return { ...state, DeleteEventStatus: "" };

        case "update_event_status":
            return { ...state, UpdateEventStatus: action.payload };
        case "clear_update_event_status":
            return { ...state, UpdateEventStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllEvents = (dispatch) => async () => {
    try {
        const response = await listAPI.get('event/getAll');
        dispatch({ type: "get_all_events", payload: response.data });
        dispatch({ type: "get_all_events_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_events_status", payload: 400 });
    }
};

const clearGetAllEventsStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_events_status" });
};


/** Create booking **/
const createBooking = dispatch => async ({ name, email, phone, event_id }) => {
    try {
        console.log("data", name, email, phone, event_id)
        let data = {
            name: name,
            email: email,
            phone_number: phone,
            event: event_id,
        }

        const response = await listAPI.post("booking/add", data, config);
        dispatch({ type: "create_booking", payload: response.data });
        dispatch({ type: "create_booking_status", payload: response.status });
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "create_booking_status", payload: 400 })
    }
}

const clearCreateBookingStatus = dispatch => () => {
    dispatch({ type: "clear_create_booking_status" });
};


export const { Provider, Context } = CreateDataContext(
    EventReducer,
    {
        getAllEvents, clearGetAllEventsStatus,
        createBooking, clearCreateBookingStatus,
    },
    {
        EventList: [],
        EventListStatus: "",
        CreateBooking: [],
        CreateBookingStatus: "",
    }
);
