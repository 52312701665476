export const faqData = [
    {
        title: 'Dog Sharing',
        subtopics: [
            {
                question: 'What even is dog sharing?',
                answer: 'Dog-sharing is when a dog-owner and non-owner have a mutually beneficial relationship built upon the shared love for a dog. The non-owner (Team Social) requests walks or playdates whenever he/she wants the companionship or activity of that dog. And the owner (Team Paws) requests walks or playdates whenever he/she wants extra activity or attention for the dog. Dog-sharing can happen as frequently as desired and dog-sharing relationships can last as long as desired. Regardless, the benefits of dog-sharing for the humans and dogs involved will result in positive memories that will last far longer',
            },
            {
                question: 'Why dog-share?',
                answer: [
                    "We have members of Team Social (our non-owners) who share the following reasons for wanting time with a dog, just part-time:",
                    {
                        list: [
                            "Exercise: wanting a companion for walks and/or runs",
                            "Companionship: working from home, just hanging w/ a furry friend",
                            "Family dynamics: Parents with dog-obsessed children, families with some dog-allergic members, etc - can find a compromise in dog-sharing",
                            "Missing their dogs: former dog-owners whose dogs have passed; college students, professionals, etc who are here temporarily with family dogs elsewhere",
                            "Life-change: Retirees, divorcees, etc can ease loneliness and loss through dog-therapy.",
                        ],
                    },
                    "We have members of Team Paws (our owners) who share the following reasons for wanting to share their dog with dog-loving neighbors:",
                    {
                        list: [
                            "Providing their dog with more affection, activity, and less alone time",
                            "Enjoying a break from dog-parenting",
                            "Saving money on dog care expenses such as walking & boarding",
                        ],
                    },
                ],
            },
            {
                question: 'Who pays for the dog’s needs: ie: dog grooming, food, treats, etc?',
                answer: 'Who pays for the dog’s needs: ie: dog grooming, food, treats, etc?  Team Paws is always the dog-owner responsible for 100% of their dog’s expenses and needs. Team Social provides dog-care in the form of walks, playdates, and sleepovers. As dog-sharing relationships progress, Team Social does eventually make purchases for their shared dog - not unlike how aunts and uncles spoil their nieces and nephews! Ultimately, however, Team Paws is the dog-owner responsible for their dog’s expenses and for setting the guidelines around their dog’s care',
            },
            {
                question: 'Do the dogs enjoy dog-sharing?',
                answer: 'Dog-sharing can be enriching and rewarding for dogs, as they benefit from more attention, activity, and affection. We advise approaching dog-sharing slowly and observing the pup’s enjoyment of the process. There are exceptions to every norm and the caring humans invested and involved in a dog’s welfare are the ones suited to decide',
            },
        ],
    },
    {
        title: 'Platform FAQs',
        subtopics: [
            {
                question: 'How does Social Paws help',
                answer: ["Technology",
                    "Social Paws is the platform designed to gather this subset of people: dog-owners and non-owners interested in dog-sharing, so that neighbors who are good matches can connect on our app, take a real life dog-walk, and potentially have a dog-sharing relationship for any amount of time.", 
                    "Process ",
                    "We have created, iterated, and tested our “Snooping, Sniffing, Sharing” © process that guides neighbors through a relationship-building journey.  Our technology-prompted concierge absorbs any awkwardness in communicating each step of the way.  So strangers can build trust over time toward becoming dog-sharing family!",
                    "Safety",
                    "We provide identity verifications, insurance, and implement vet-advised practices. While we want everyone to have the life-changing experience of dog-sharing, whether they are an owner or a non-owner, we appreciate the risks involved and keep your safety at heart. ",
                ]
            },
            {
                question: 'How Much Does Social Paws Cost?',
                answer: ["Social Paws, when subscriptions are live, will offer a freemium, basic ($19.99), and VIP ($29.99) membership that will translate to increasing amounts of dog-care [for owners] or dog-therapy [for non-owners]. This is a massive savings compared to traditional pet-care or pet-ownership, thus a cost well worth the value.  Currently, we are in beta, so Social Paws is completely free! We are paying to make this incredible service available for you so that we can perfect dog-sharing the Social Paws way.",
                    "That’s amazing! How can I help?",
                    "Share your feedback! Tell us what you love, what you hate, and what you want to see. When we ask for feedback via surveys or polls, respond. And share Social Paws with your dog-loving friends - with apps like this, the more, the merrier",
                ]
            },     
             {
                question: 'That’s amazing! How can I help? ',
                answer: 'Share your feedback! Tell us what you love, what you hate, and what you want to see. When we ask for feedback via surveys or polls, respond. And share Social Paws with your dog-loving friends - with apps like this, the more, the merrier',                       
            },
        ]           
    },
    {
        title:'Safety FAQs',
        subtopics:[{
            question:'Is dog-sharing safe?',
            answer:["Remember that Social Paws is simply a matching platform. It is important to vet one another for experience, safety, and comfort. We provide identity verifications to ensure that profiles are of real people with their accurate information. This does not include a criminal background check. We provide insurance that covers up to $1,000,000 in an incident. We have everyone complete a profile that ensures that dogs are fully vaccinated and that humans are who they say they are. Our quiz also covers the amount of experience that someone has with dogs, dogs’ breeds and aggression history.",
            
                "You still play a key role in your own safety. View interactions on Social Paws as if you are meeting someone new at the dog park and de-risk yourself by doing your due diligence and vetting your new dog-loving acquaintance. Vetting one another for personality, honesty, and communication-style are your responsibility. We recommend getting to know one another and building trust over time through our “Snooping, Sniffing, Sharing” © process",
                {
                    topic: "Safe Dog-Sharing 101:",
                    // description: "Safe Dog-Sharing involves building trust and ensuring compatibility between owners and dog lovers. Follow these steps for a safe and enjoyable experience:",
                    // list: [
                    //     "Step 1: Snooping – Browse profiles and observe behaviors. Join discussions in the Dog Park to learn more about potential matches.",
                    //     "Step 2: Sniffing – Arrange interactions like walks to assess compatibility. Discuss key topics like goals, the dog’s behavior, and potential triggers.",
                    //     "Step 3: Sharing – Build trust gradually by starting with supervised activities like walks and playdates before moving to unsupervised interactions."
                    // ]
                },
                {
                    topic: "Phase 1: Snooping - Observe each other",
                    description: "Your first step is to observe your neighborhood humans and dogs. You do this by browsing through profiles, reading more into the ones that pique your interest, and observing discussions and announcements on our feed, “the Dog Park.” Once you’re interested in a profile enough to interact, you move into our next phase, “Sniffing!”",
                },
                {
                    topic: "Phase 2: Sniffing - Get to know each other",
                    description: "Sniffing includes either tossing a treat to a Team Paws/ dog profile or wagging your tongue to a Team Social/ human profile, either in our profile browsing experience or in the Dog Park. It can also mean sending a message from either experience.  Use in-app interactions to set up a real-life dog-walk together. Plan on several walks over time to sniff each other out. Include these topics in your chat:   ",
                   
                },
                {
                    topic: " ",
                    description: "Human connection",
                    list: [
                        "What do you hope to get out of dog sharing?"
                    ],
                },
                {
                    topic: " ",
                    description: "Dog connection",
                    list: [
                        "What issues is the dog working on?",
                        "What triggers the dog?",
                        "Have there been any fights?"
                    ],
                },
                {
                    topic: " ",
                    description: "By the end of “Sniffing,” Team Social should have completed a walk encountering various scenarios (other dogs, people, triggers, etc.) with Team Paws supervising. The goal of “Sniffing” is for Team Paws (dog-owner + dog) and Team Social (non-owner) to move confidently into “Sharing,” where the Team Social will have walks, playdates, and sleepovers on their own with the dog. ",
                },
                {
                    topic: "There is no obligation",
                    description: "If at any point, either team isn’t comfortable with the relationship, there is no obligation to proceed. Share with the other party that you want to discontinue. Dog-sharing means that both parties feel confident and excited about it. If you suspect that someone doesn't have the right intention, or is a risk to our community, report the profile and or share a review of the same.",
                },
                {
                    topic: "Phase 3: Sharing - Build trust over time",
                    description: "Once both teams feel ready and excited to move forward into dog-sharing, start small and build trust over time.",
                    list: [
                        "Walk: Let Team Paws take the dog on their own for a walk.",
                        "Short Playdate: After a few walks, allow the dog a playdate while Team Social works from home. ",
                        "Long Playdate: Then, maybe the pup can join the next time Team Social ventures on a hike."
                    ],
                },
              
                 "Ongoing Communication ",
                 "Always maintain open lines of communication. Both parties want to feel supported and confident participating together in the important responsibility of dog-sharing. Reliable communication ensures that when there is an incident or emergency, you are available to one another.  ",
                
            ]
        },
        {
            question:'Do you conduct background checks?',
            answer:["We are partnered with Chubb Insurance so that we may cover an incident once your personal policies are exhausted. This means that: ",
                "in cases where a human is injured [by a dog,] the injured party’s personal healthcare insurance is the first line of response",
                "in cases where property is damaged [by a dog,] the property owners homeowner/ renters/ property insurance is the first line of response in cases where a dog is injured, the dogs pet insurance policy is the first line of response.",
                 "Social Paws policy may act as a backup if those coverages are exhausted or don’t apply. "
            ]
        },
        {
            question:'Are dog-shares insured?',
            answer:'Yes, we do provide an insurance policy for all of our users that covers up to $1,000,000 per occurrence.   '
        },
        {
            question:'Explain Identity Verification',
            answer:["Social Paws does not offer background checks, but we do offer identity verification through Plaid.  However, one of the keys to success of dog-sharing will always come down to you doing your research, using your best judgment, and remembering that every new interaction carries an inherent risk. View every match as if you are meeting someone new at the dog park and de-risk yourself by spending adequate time building trust with your new dog-loving pals.",
                "We will also be offering vaccine verification through Vet Verifi. Both Plaid and Vet Verifi are 3rd party applications."
            ]
        },
        {
            question:'What about Vet Information?',
            answer:'We require Team Paws to complete a Vet Form upon entering the “Sharing” Phase. This means that they are sharing their vet’s contact information as well as giving the Team Social member the right to act on their behalf in the dog’s best interest in the case that Team Paws is unreachable in an emergency.  '
        },
        {
            question:'What if an incident/ accident occurs during a dog-share',
            answer: [
                "Team Social: communicate with Team Paws immediately and transparently: ",
                {
                    list: [
                        "Report what occurred",
                        "Decide on next steps together",
                        "If Team Paws is not accessible, use their vet form to contact their vet.",
                        "If vet care is necessary, provide the vet form to act on Team Paws’ behalf. ",
                        "If an insurance claim is necessary, involve your health or property insurance  ",
                    ],
                },
            ],
        }
    ]
    },
    {
        title:'Team Paws FAQs',
        subtopics:[{
            question:'Can I meet Team Social before I leave my dog in their care?',
            answer:'Yes, in fact, it’s required. Your first meeting with Team Social is a dog-walk where everyone gets to know each other, using in-person conversation and behavior to vet each other. Meet as many times as you want, conducting walks, demonstrating leash-behavior, encountering other dogs and humans together, etc, until you feel confident and excited to conduct your first dog-share with Team Social'
        },
        {
            question:'Can I drop my dog off when I meet Team Social for the first time?',
            answer:'No. Social Paws is not a drop-off dog-care service; we are in the business of relationship-building. We make an introduction and want you to move that relationship through “Sniffing” before “Sharing.” Your first in-person meeting will be a dog-walk together.'
        },
        {
            question:'Where will this meeting take place?',
            answer:'Invite Team Social to join you when and where you walk your dog. This is a public route, park, etc. Use the walk to chat about their experience with dogs and observe your dogs’ response to Team Social. Before moving into Sharing, we request that you be able to observe Team Social leading the walk, holding the leash, and encountering various situations, with you simply observing.'
        },
        {
            question:'What information do I share with Team Social?',
            answer:'Share as much information as possible with Team Social. We advise covering your dog’s triggers, history of fights, and issues you are still working on with your pup. While this is uncomfortable, it is honest and sets everyone up for the right dog-share. And always maintain open lines of communication because questions crop up in real time'
        },
        {
            question:'Can Team Social let my dog off-leash?',
            answer:[
                "It’s safest to request that Team Social keep your dog on leash 100% of the time, with the exception of dog parks/ enclosed spaces and: ",
                {
                    list: [
                        "Set clear guidelines for your dog’s leashing (when, where, why - ie: keep a close leash while walking past other dogs because my dog still jumps excitedly at other dogs)",
                        "Remember that Team Social does not know your dog the way you do",
                        "Ensure that your dog’s harness and leash are secure",
                    ],
                },
                "While your dog may be competent off-leash with you, that does not mean such will be the case with Team Social. Should your dog get lost or injured in the hands of Team Social, Social Paws’ insurance policy does not compensate for a missing dog and is only involved after your pet’s health insurance is exhausted. See insurance policy breakdown here"
            ],
        },
        {
            question:'Should I tip Team Social?',
            answer:'Social Paws is passion vs income motivated. Ultimately, the reward is baked in. Dog-sharing is a relationship that allows more humans to enjoy the love and companionship of a dog. We have a “treating” feature in our roadmap. For now, just tell them how you appreciate them!'
        },
        {
            question:'What vaccinations are required of my dog to participate with Social Paws?',
            answer:[
                {
                list: [
                    "We require that our dogs have the following vaccinations before shared on Social Paws",
                    "DA2PP vaccine ( covers infectious diseases ie: Distemper, Parvo)",
                    "Rabies vaccine (required by law in NYC)",
                    "Bordetella (Also known as “kennel cough,” recommended for dogs in social settings: dog parks, boarding or daycare, groomers)",
                ],
             },
                "Just like any NYC groomer or dog care facility, we require that every dog on our platform is up-to-date on all of these vaccinations. We confirm this in our dog profile onboarding process and reference it in our terms and conditions. It is for everyone’s safety."
            ],
        },
        {
            question:'Do you have breed restrictions?',
            answer:'We have no breed restrictions. Regardless of breed, it is Team Paws’ responsibility to communicate care guidelines and behavior issues and it is Team Socials’ responsibility to adhere to them and remain aware during shares.'
        },
        {
            question:'What if I feel like the dog was upset/ harmed by Team Social?',
            answer:'We have no breed restrictions. Regardless of breed, it is Team Paws’ responsibility to communicate care guidelines and behavior issues and it is Team Socials’ responsibility to adhere to them and remain aware during shares.'
        },
    ]
    },
    {
        title:'Team Social FAQs',
        subtopics:[{
            question:'Can I meet Team Paws before caring for their dog',
            answer:'It’s required. Meet as many times as you want, conducting walks that include holding the leash and encountering various situations (other dogs, children, etc) with Team Paws supervising. Before any playovers or stayovers, invite Team Paws to see your space. Meet until you feel confident and excited to have a walk or playdate on your own.'
        },{
            question:'Does Team Paws provide toys, treats, food?',
            answer:'What you want will depend on the activity you request. If you’re requesting a walk, all you’ll want is a leash. And if treats and a ball are a good idea, Team Paws will provide that. And so on for playdates and sleepovers. Team Paws is responsible for providing all that’s necessary for the care ahead. You are not. Communicate as needed.'
        },
        {
            question:'Are the dogs that I am matching with, vaccinated?',
            answer:'We require that all dogs on Social Paws are current Rabies, DA2PP, and Bordetella vaccines. You will see a dog’s vaccination status confirmed on their profile. Many dogs wear a Rabies vaccine tag on their collar. And it’s your right to talk about vaccination status and records during our “Sniffing” phase until you feel confident and excited to move into our “Sharing” phase.'
        },
        {
            question:'Can I let the dog off-leash?',
            answer:[
                "The safest measure is to keep any dog that you are caring for on-leash when out and about. The golden rule is to follow the care guidelines that Team Paws shares prior to moving into “Sharing” and that are available for you to access from Team Paws’ profile anytime thereafter. ",
                "Should any incidents/ accidents occur during your dog-share, personal health & property insurances are the first line of defense."
            ]
        },
        {
            question:'Where can I take the dog? Can I take the dog on a hike? Can I give the dog a trim? Can I treat the dog cheese?',
            answer:'Remember, the dog you are sharing has an official owner and you as Team Social are required to yield to their guidelines when caring for their dog. As always, communication is key; always ask. When in doubt, ask!  Team Paws gets to decide - if you don’t hear back, do not act. Remember, you benefit from the freedom of not owning a dog - this is the tradeoff.'
        },
        {
            question:'What if I feel like the dog is being neglected by its owner?',
            answer:'If you sense that an owner is not fit, ask Team Paws questions to gather information without judgment. This is a situation in which you can use reviewing and reporting in the spirit of community policing - write what you would want others to share with you about this member. And we invite you to call us if you want to talk through your concerns and next steps.'
        },
    ]
    },
    
      
    ];
